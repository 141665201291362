const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        // api: "https://localhost:44348/",
        api: "https://vungtau-api.cgis.asia/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "http://localhost:3001",
        domainName: "",
  },
  production: {
      api: "https://vungtau-api.cgis.asia/",
      domainAdminSide: "https://admin.bariavungtau.cgis.asia",
      domainUserSide: "https://bariavungtau.cgis.asia",
      domainName: "bariavungtau.cgis.asia",
  },
};

module.exports = apiEnvironment[env];
